import { inject, ref } from 'vue';
import { pageKey, routeKey, setMeta, Meta } from '@drapejs/core';
import { injectionKeys as litiumKeys } from '@motillo/drapejs-litium';
import useContext from '@/composables/useContext';

export default function (middleware?: (meta: Meta) => Meta) {
  const page = inject(pageKey, ref());
  const route = inject(routeKey, <any>{});
  const toLitiumMediaUrl = inject(litiumKeys.toLitiumMediaUrlKey, () => '');
  const { channel } = useContext();

  setMeta(() => {
    const seo = page.value.dataJson?.seo;
    const title = seo?.title
      || page.value.title
      || page.value.name
      || page.value.fields?.Title
      || page.value.fields?._name;
    const description = seo?.description || page.value.fields?._description || null;
    const canonicalUrl = `${route.protocol}//${route.host}${route.pathname?.replace(/\/+$/, '')}`;
    const image = seo?.imageId;

    const result = {
      title,
      lang: channel.value.language,
      props: {
        'og:title': title,
        'og:description': description,
        description,
        'og:url': canonicalUrl,
        'og:image': image
          ? toLitiumMediaUrl(image, { maxWidth: 1200, maxHeight: 630 })
          : null,
        'og:type': 'website',
        ...(seo?.robots && { robots: seo?.robots }),
      },
      links: [
        {
          rel: 'canonical',
          href: canonicalUrl,
        },
      ],
    };

    if (seo?.robots) {
      result.props.robots = seo?.robots;
    }

    return middleware ? middleware(result) : result;
  });
}
