<script>
export default {
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    productsPrice() {
      if (!this.order?.totalProducts || this.order.totalProducts === 0) {
        return "";
      }
      return this.$formatPrice(this.order.totalProducts || 0);
    },
    addonsPrice() {
      if (!this.order?.totalAddons || this.order.totalAddons === 0) {
        return "";
      }
      return this.$formatPrice(this.order.totalAddons || 0);
    },
    printsPrice() {
      if (!this.order?.totalPrints || this.order.totalPrints === 0) {
        return "";
      }
      return this.$formatPrice(this.order.totalPrints || 0);
    },
    giftCardShipping() {
      if (!this.order?.totalGiftCardShipping || this.order.totalGiftCardShipping === 0) {
        return "";
      }
      return this.$formatPrice(this.order.totalGiftCardShipping || 0);
    },
    discountAmount() {
      const discount = Math.abs(this.order?.totalDiscount || 0);
      if (!discount) {
        return "";
      }
      return this.$formatPrice(discount * -1);
    },
    vatAmount() {
      return this.$formatPrice(this.order?.totalVat || 0);
    },
    grandTotal() {
      return this.$formatPrice(this.order?.grandTotal || 0);
    },
    totalDeliveryCost() {
      return this.order?.totalDeliveryCost
        ? this.$formatPrice(this.order.totalDeliveryCost)
        : this.$globalTexts.checkout__summary_shipping_price;
    }
  },
  methods: {},
  created() {},
};
</script>

<template>
  <div class="receipt-order-summary">
    <div class="receipt-order-summary__content">
      <div class="receipt-order-summary__caption">{{ $globalTexts.checkout__summary }}</div>
      <div class="receipt-order-summary__row">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.checkout__summary_products }}
        </div>
        <div class="receipt-order-summary__row-value">{{ productsPrice }}</div>
      </div>
      <div class="receipt-order-summary__row" v-if="discountAmount">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.checkout__summary_discount }}
        </div>
        <div class="receipt-order-summary__row-value">
          {{ discountAmount }}
        </div>
      </div>
      <div class="receipt-order-summary__row" v-if="addonsPrice">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.giftcard__addons }}
        </div>
        <div class="receipt-order-summary__row-value
            receipt-order-summary__shipping-cost">
          {{ addonsPrice }}
        </div>
      </div>
      <div class="receipt-order-summary__row" v-if="printsPrice">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.checkout__prints }}
        </div>
        <div class="receipt-order-summary__row-value
            receipt-order-summary__shipping-cost">
          {{ printsPrice }}
        </div>
      </div>
      <div class="receipt-order-summary__row" v-if="giftCardShipping">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.giftcard__shipping }}
        </div>
        <div class="receipt-order-summary__row-value
            receipt-order-summary__shipping-cost">
          {{ giftCardShipping }}
        </div>
      </div>
      <div class="receipt-order-summary__row">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.checkout__summary_shipping }}
        </div>
        <div
          class="
            receipt-order-summary__row-value
            receipt-order-summary__shipping-cost
          "
        >
          {{ totalDeliveryCost }}
        </div>
      </div>
      <div class="receipt-order-summary__row">
        <div class="receipt-order-summary__row-caption">
          {{ $globalTexts.checkout__summary_vat }}
        </div>
        <div class="receipt-order-summary__row-value">{{ vatAmount }}</div>
      </div>
      <div class="receipt-order-summary__row">
        <div
          class="
            receipt-order-summary__row-caption
            receipt-order-summary__grand-total-caption
          "
        >
          {{ $globalTexts.checkout__summary_total }}
        </div>
        <div
          class="
            receipt-order-summary__row-value
            receipt-order-summary__grand-total-value
          "
        >
          {{ grandTotal }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.receipt-order-summary__place_order {
  width: 100%;
}

.receipt-order-summary__content {
  background-color: white;
  padding: 1rem;
}

.receipt-order-summary__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 0.5rem 0 0.75rem 0;
}

.receipt-order-summary__row {
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 14px;
  color: #000000;
}

.receipt-order-summary__row-value {
  font-family: "Mulish";
  font-weight: 700;
}

.receipt-order-summary__grand-total-caption,
.receipt-order-summary__grand-total-value {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
  padding-top: 1rem;
}

.receipt-order-summary__shipping-cost {
  font-size: 12px;
  font-weight: 400;
}

@media (--tabletAndDesktop) {
  .receipt-order-summary__content {
    padding: 20px 30px;
  }
}
</style>