<script>
import { getAddonTitle } from '@/helpers/printess';
import ReceiptOrderInfoRow from './ReceiptOrderInfoRow';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    ReceiptOrderInfoRow,
  },
  props: {
    order: {
      type: Object,
    },
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    return { websiteText };
  },
  computed: {
    rows() {
      return this.order.rows || [];
    },
    prints() {
      return this.order.rows.reduce(
        (rows, curr) =>
          rows.concat(curr?.addOns || []).concat(curr?.printRow || []),
        []
      );
    },
    addons() {
      return this.order.rows.reduce(
        (rows, curr) =>
          rows.concat(
            curr?.giftCardInfo?.addons?.map((a) => {
              const extraInfo = a.extraInfo;
              let image = null;
              if (!!extraInfo) {
                const info = JSON.parse(extraInfo);
                image = info.ThumbnailUrl;
              }
              return {
                name: getAddonTitle(a),
                price: a.price,
                quantity: a.quantity,
                unitPrice: a.unitPrice || a.price / a.quantity,
                image: image,
              };
            }) || []
          ),
        []
      );
    },
  },
  methods: {
    openCheckoutDeliveryOption() {
      this.isCheckoutDeliveryOptionOpen = true;
    },
    closeCheckoutDeliveryOption() {
      this.isCheckoutDeliveryOptionOpen = false;
    },
  },
};
</script>

<template>
  <div class="receipt-order-info">
    <div class="receipt-order-info__general">
      <div>
        {{ websiteText('orderdetails__order_number').value }}:
        {{ order.externalOrderId }}
      </div>
    </div>
    <div class="receipt-order-info__rows">
      <receipt-order-info-row
        v-for="(row, idx) in rows"
        :key="idx"
        :row="row"
      />
    </div>
    <div class="receipt-order-info__rows">
      <receipt-order-info-row
        v-for="(row, idx) in prints"
        :key="idx"
        :row="row"
      />
    </div>
    <div class="receipt-order-info__rows">
      <receipt-order-info-row
        v-for="(row, idx) in addons"
        :key="idx"
        :row="row"
      />
    </div>
  </div>
</template>

<style>
.receipt-order-info {
  background-color: white;
  padding: 1rem;
}

.receipt-order-info__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 0.25rem;
  display: none;
}

.receipt-order-info__general {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
}

.receipt-order-info__edit-rows-caption {
  text-align: center;
  padding-top: 1rem;
  font-family: 'Mulish';
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  color: #6d6d8e;
}

.receipt-order-info__rows {
  margin-top: 1.25rem;
}

@media (--tabletAndDesktop) {
  .receipt-order-info {
    padding: 30px;
  }

  .receipt-order-info__caption {
    display: block;
  }
}
</style>
